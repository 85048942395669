import eventsHandler from '@/services/common/eventsHandler.js'

class departmentForm extends eventsHandler {
  constructor() {
    super()
    this._department = {}
    this._valid = false
  }

  get department() {
    const department = {
      ...this._department
    }
    return department
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._department.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetDepartment() {
    this._department = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {department: {}})
  }

  updateField(key, value) {
    this._department[key] = value
    this._executeCallbacksOf('update', {department: this._department})
    this._executeCallbacksOf('update-' + key, {key: this._department[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(department) {
    this._department = department
    this._executeCallbacksOf('update', {department: this._department})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new departmentForm()